<template>
  <div>
    <div class="authortitle">
      <i class="el-icon-arrow-left" @click="back" style="cursor: pointer"></i
      >服务评价
    </div>
    <div style="padding: 20px">
      <p>平台评价</p>
      <div style="margin-top: 10px">
        <img
          v-for="(item, index) in startlevel"
          :key="index"
          class="start"
          :src="xj > index ? yellowstart : start"
          @click="pingjia(index)"
        />
      </div>

      <div>
        <el-input
          type="textarea"
          v-model="ptdesc"
          resize="none"
          placeholder="请输入对平台的评价"
        ></el-input>
      </div>
    </div>
    <div style="padding: 20px" v-if="show">
      <p>服务商评价</p>
      <div style="margin-top: 10px">
        <img
          v-for="(item, index) in startlevel"
          :key="index"
          class="start"
          :src="xj2 > index ? yellowstart : start"
          @click="pingjia2(index)"
        />
      </div>
      <div>
        <el-input
          type="textarea"
          v-model="fwsdesc"
          resize="none"
          placeholder="请输入对服务商的评价"
        ></el-input>
      </div>
    </div>
    <div style="padding: 20px; display: flex; justify-content: flex-end">
      <el-button type="danger" @click="addpj">发布</el-button>
    </div>
  </div>
</template>

<script>
import { addEvaluation } from "../../../API/ZZD/service.js";
export default {
  data() {
    return {
      ptdesc: "",
      fwsdesc: "",
      startlevel: [{}, {}, {}, {}, {}],
      xj: 0,
      xj2: 0,
      yellowstart: require("../../../assets/image/setting/xing2.png"),
      start: require("../../../assets/image/setting/xing1.png"),
      show: true,
    };
  },
  mounted() {
    if (this.$route.query.fwid == 1) {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  watch: {
    ptdesc(newval) {
      console.log(newval);
      let val1 = newval.replace(/代写/g, "**");
      let val2 = val1.replace(/代发/g, "**");
      this.ptdesc = val2.replace(/代投/g, "**");
    },
  },
  methods: {
    pingjia(e) {
      this.xj = e + 1;
    },
    pingjia2(e) {
      this.xj2 = e + 1;
    },
    addpj() {
      let wid = this.$route.query.wid;
      addEvaluation(wid, this.xj, this.xj2, this.ptdesc, this.fwsdesc).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("评论成功");
            history.back();
          }
        }
      );
    },

    back() {
      history.back();
    },
  },
};
</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.start {
  width: 20px;
  cursor: pointer;
}
</style>